<template>
  <div>
    <a-modal
      v-model="isShow"
      centered
      :mask-closable="false"
      :ok-button-props="{ props: { loading: submitting } }"
      title="开单"
      @ok="handleSubmit"
      width="1300px"
    >
      <a-form
        class="custom-compact-form"
        :form="form"
        :label-col="{ span: 7 }"
        :wrapper-col="{ span: 13 }"
        @submit="handleSubmit"
      >
        <a-row>
          <a-col span="12">
            <div class="ant-row ant-form-item">
              <div class="ant-col ant-col-6 ant-form-item-label" style="font-size: 16px">申请人信息</div>
            </div>
            <a-form-item label="申请人">
              <a-input
                v-decorator="['apply_user_name', {
                  normalize: this.$lodash.trim,
                  rules: [
                    { required: true, message: '请输入申请人真实姓名' },
                    { max: 30, message: '最多30个字符' },
                  ]
                }]"
                @change="changeApplyUser"
                autocomplete="off"
              />
            </a-form-item>
            <a-form-item label="联系电话">
              <a-input
                v-decorator="['apply_user_phone', {
                  normalize: this.$lodash.trim,
                  rules: [
                    { required: true, message: '请输入联系电话' },
                    { pattern: /^1[3-9]\d{9}$/, message: '联系电话格式不正确' },
                    { max: 11, message: '联系电话最多11位'},
                  ]
                }]"
                autocomplete="off"
                @change="changeApplyUserPhone"
              />
            </a-form-item>

            <a-form-item label="验证码">
              <a-input
                style="width: 220px"
                v-decorator="['sms_captcha', {
                  normalize: this.$lodash.trim,
                  rules: [
                    { required: true, message: '请输入验证码' },
                    { max: 6, message: '最多6个字符' },
                  ]
                }]"
                autocomplete="off"
              />
              <a-button type="primary" @click="getSmsCaptcha" :disabled="disabled" class="margin-left-10">
                {{ btnTitle }}
              </a-button>
            </a-form-item>
            <a-form-item label="与逝者关系">
              <a-select
                v-decorator="['relation']"
                placeholder="请选择与逝者关系"
                allow-clear
                mode="combobox"
              >
                <a-select-option
                  v-for="name in relations"
                  :key="name"
                  :value="name"
                >
                  {{ name }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <div class="ant-row ant-form-item">
              <div class="ant-col ant-col-6 ant-form-item-label" style="font-size: 16px">丧属信息
              </div>
            </div>
            <a-form-item label="同申请人">
              <a-checkbox v-model:value="sameToApply" @change="changeSameToApply"></a-checkbox>
            </a-form-item>
            <a-form-item label="丧属">
              <a-input
                v-decorator="['master_name', {
                  normalize: this.$lodash.trim,
                  rules: [
                    { required: true, message: '请输入真实姓名' },
                    { max: 30, message: '最多30个字符' },
                  ]
                }]"
                :disabled="sameToApply"
                autocomplete="off"
              />
            </a-form-item>
            <a-form-item label="联系电话">
              <a-input
                v-decorator="['master_phone_number', {
                  normalize: this.$lodash.trim,
                  rules: [
                    { required: true, message: '请输入联系电话' },
                    { pattern: /^1[3-9]\d{9}$/, message: '联系电话格式不正确' },
                    { max: 11, message: '联系电话最多11位'},
                  ]
                }]"
                :disabled="sameToApply"
                autocomplete="off"
              />
            </a-form-item>
            <div class="ant-row ant-form-item">
              <div class="ant-col ant-col-6 ant-form-item-label" style="font-size: 16px">接运信息</div>
            </div>
            <a-form-item label="地址检索">
              <a-input-search
                style="width: 100%;"
                v-decorator="['address_search', {
                  normalize: this.$lodash.trim,
                  rules: [
                    { required: true, message: '请输入地点名称' },
                    { max: 6, message: '最多6个字符' },
                  ]
                }]"
                enter-button="定位"
                @search="location"
              />
            </a-form-item>
            <a-form-item label="接运地点">
              <a-select
                v-decorator="['origination', {
                  rules: [
                    { required: true, message: '请选择接运地点' },
                  ]
                }]"
                placeholder="请选择接运地点"
                @change="changLocation"
              >
                <a-select-option
                  v-for="(location,index) in locationOptions"
                  :key="index"
                  :value="index"
                >
                  {{ location.title }}({{ location.address }})
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="详细地址">
              <a-input
                v-decorator="['detail', {
                  rules: [
                    { max: 100, message: '最多100个字符'},
                  ]
                }]"
                autocomplete="off"
              />
            </a-form-item>
            <a-form-item label="目的地点">
              <a-select
                v-decorator="['destination_id', {
                  rules: [
                    { required: true, message: '请选择接目的地点' },
                  ]
                }]"
                placeholder="请选择接目的地点"
              >
                <a-select-option
                  v-for="destination in destinationOptions"
                  :key="destination.id"
                  :value="destination.id"
                >
                  {{ destination.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <div class="ant-row ant-form-item">
              <div class="ant-col ant-col-6 ant-form-item-label" style="font-size: 16px">备注</div>
            </div>
            <a-form-item label="备注">
              <a-textarea
                v-decorator="['remark', {
                  normalize: this.$lodash.trim,
                  rules: [
                    { max: 300, message: '最多300个字符' },
                  ]
                }]"
              />
            </a-form-item>
          </a-col>
          <a-col span="12">
            <div class="ant-row ant-form-item">
              <div class="ant-col ant-col-6 ant-form-item-label" style="font-size: 16px">逝者信息</div>
            </div>
            <a-form-item label="逝者信息">
              <a-input
                v-decorator="['death_name', {
                  normalize: this.$lodash.trim,
                  rules: [
                    { required: true, message: '请输入逝者姓名' },
                    { max: 30, message: '最多30个字符' },
                  ]
                }]"
                autocomplete="off"
              />
            </a-form-item>
            <a-form-item label="身份证号">
              <a-input
                v-decorator="['identity_card_no', {
                  normalize: this.$lodash.trim,
                  rules: [
                    { pattern: /^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/, message: '身份证号格式不正确' },
                  ]
                }]"
                @change="calAge"
                autocomplete="off"
              />
            </a-form-item>
            <a-form-item label="性别">
              <a-radio-group
                v-decorator="['sex',{initialValue:0},
                              {rules: [
                                { required: true, message: '请选择逝者性别' },
                              ]}]"
              >
                <a-radio v-for="(option,index) in sexOptions" :value="option.value" :key="index">{{ option.text }}</a-radio>
              </a-radio-group>
            </a-form-item>
            <a-form-item label="年龄">
              <a-input-number
                style="width: 100%;"
                :min="1"
                :max="999"
                :step="1"
                :precision="0"
                v-decorator="['age', {
                  normalize: this.$lodash.trim,
                  rules: [
                    { required: true, message: '请输入逝者年龄' },
                  ]
                }]"
              />
            </a-form-item>
            <a-form-item label="宗教信仰">
              <a-select
                v-decorator="['religion',{
                  rules: [ { required: true, message: '请选择宗教信仰' },]
                }]"
                placeholder="请选择宗教信仰"
                allow-clear
                mode="combobox"
              >
                <a-select-option
                  v-for="name in religionOptions"
                  :key="name"
                  :value="name"
                >
                  {{ name }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="是否已逝世">
              <a-radio-group
                v-decorator="['dead',{initialValue:'false'},
                              {rules: [
                                { required: true, message: '请选择是否已逝世' },
                              ]}]"
                @change="handleChangeDead"
              >
                <a-radio value="true">是</a-radio>
                <a-radio value="false">否</a-radio>
              </a-radio-group>
            </a-form-item>
            <a-form-item label="逝世时间" v-if="isDead === true">
              <a-date-picker
                style="width: 100%;"
                v-decorator="[
                  'death_date',
                  {
                    rules: [{ required: true, message: '请选逝世时间' }],
                  },
                ]"
                format="YYYY-MM-DD HH:mm"
                :show-time="{ format: 'HH:mm' }"
                placeholder="请选逝世时间"
              />
            </a-form-item>
            <a-form-item label="逝世原因" v-if="isDead === true">
              <a-select
                v-decorator="['dead_reason', {
                  rules: [
                    { required: true, message: '请选择逝世原因' },
                  ]
                }]"
                placeholder="请选择逝世原因"
              >
                <a-select-option
                  v-for="reason in deadReasonOptions"
                  :key="reason.value"
                  :value="reason.value"
                >
                  {{ reason.text }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="逝者身上有无贵重物品" v-if="isDead === true">
              <a-radio-group
                v-decorator="['has_valuables',{initialValue:'false'},{rules: [
                  { required: true, message: '请选择逝者身上有无贵重物品' },
                ]}]"
              >
                <a-radio value="true">有</a-radio>
                <a-radio value="false">无</a-radio>
              </a-radio-group>
              <a-textarea
                v-decorator="['valuables_content', {
                  normalize: this.$lodash.trim,
                  rules: [
                    { max: 300, message: '最多300个字符' },
                  ]
                }]"
                placeholder="若有，请仔细填写物品名称、数量(必填)，若因未填写清楚产生的法律问题纠纷，平台概不负责；若无则不需填写"
              />
            </a-form-item>
            <a-form-item label="逝者身上是否有伤口破损" v-if="isDead === true">
              <a-radio-group
                v-decorator="['damaged',{initialValue:'false'},{rules: [
                  { required: true, message: '请选择逝者身上是否有伤口破损' },
                ]}]"
              >
                <a-radio value="true">有</a-radio>
                <a-radio value="false">无</a-radio>
              </a-radio-group>
              <a-textarea
                v-decorator="['damaged_content', {
                  normalize: this.$lodash.trim,
                  rules: [
                    { max: 300, message: '最多300个字符' },
                  ]
                }]"
                placeholder="若有，请仔细描述伤口位置、数量(必填)，若因未填写清楚产生的法律问题纠纷，平台概不负责；若无则不需填写"
              />
            </a-form-item>
            <a-form-item label="订单负责人">
              <a-select
                v-decorator="['user_id', {
                  rules: [
                    { required: true, message: '请选择订单负责人' },
                  ]
                }]"
                placeholder="请选择订单负责人"
                @focus="fetchUserOptions"
              >
                <a-select-option
                  v-for="user in userOptions"
                  :key="user.id"
                  :value="user.id"
                >
                  {{ user.emp_name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
      <div ref="container" />
    </a-modal>
  </div>
</template>

<script>
import { deadReasonOptions, relationOptions, religionOptions, sexOption } from '@/utils/enum'
import { locationSearch } from '@/api/map'
import { findFuneralHomeEffectiveOptions } from '@/api/funeral_home'
import { sendSmsCaptcha } from '@/api/sms_captcha'
import { findTenantServiceOptions } from '@/api/user'
import { alertMessage } from '@/utils/alert_message'
import { appointment } from '@/api/order'
import { formatDateTimeMin } from '@/utils/time'
import { calDeathAge } from '@/utils/idcard'

export default {
  name: 'Appointment',
  props: {
    visible: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: 'appointment' }),
      submitting: false,
      relations: relationOptions(),
      locationOptions: [],
      religionOptions: religionOptions(),
      userOptions: [], // 订单负责人
      deadReasonOptions: deadReasonOptions(),
      sexOptions: sexOption(),
      isDead: false,
      destinationOptions: [],
      amap: {},
      marker: [],
      createMap: true,
      longitude: 0,
      latitude: 0,
      btnTitle: '获取验证码',
      disabled: false,
      address: '',
      locationTitle: '',
      sameToApply: true,
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  created() {
    this.getDestinations()
  },
  methods: {
    changeApplyUser(val) {
      if (this.sameToApply) {
        this.form.setFieldsValue({master_name: val.target.value})
      }
    },
    changeApplyUserPhone(val) {
      if (this.sameToApply) {
        this.form.setFieldsValue({master_phone_number: val.target.value})
      }
    },
    changeSameToApply(val) {
      if (this.sameToApply) {
        this.form.setFieldsValue({master_name: this.form.getFieldValue('apply_user_name')})
        this.form.setFieldsValue({master_phone_number: this.form.getFieldValue('apply_user_phone')})
      }
    },
    calAge(e) {
      this.$nextTick(() => {
        this.form.setFieldsValue({ age: calDeathAge(e.target.value) })
      })
    },
    getDestinations() {
      findFuneralHomeEffectiveOptions().then((res) => {
        if (res.code === 0) {
          this.destinationOptions = res.data
        }
      })
    },
    handleSubmit() {
      this.form.validateFields((err, values) => {
        if (!err) {
          this.submitting = true
          const data = {
            ...values
          }
          if (values.has_valuables === 'true' && values.valuables_content === '') {
            alertMessage({
              type: 'error',
              content: '请仔细填写逝者身上填写物品名称、数量'
            })
            this.submitting = false
            return
          }
          if (values.damaged === 'true' && values.damaged_content === '') {
            alertMessage({
              type: 'error',
              content: '请仔细描述逝者身上描述伤口位置、数量'
            })
            this.submitting = false
            return
          }
          const fieldsValue = this.form.getFieldsValue()
          const deathDate = formatDateTimeMin(fieldsValue.death_date)
          const saveData = Object.assign(data,
            {
              longitude: this.longitude,
              latitude: this.latitude,
              origination: this.address,
              death_date: deathDate
            })
          appointment(saveData).then((res) => {
            if (res.code === 0) {
              // 关闭模态框
              this.isShow = false
              // 告知父组件已完成
              this.$emit('completed')
            }
            this.submitting = false
          })
        }
      })
    },
    initMap() {
      const center = new TMap.LatLng(31.981234, 120.894518)
      const map = new TMap.Map(this.$refs.container, {
        center: center,
        zoom: 13
      })
      const marker = new TMap.MultiMarker({
        map: map,
        styles: {
          current: new TMap.MarkerStyle({
            width: 34,
            height: 46,
            anchor: { x: 36, y: 32 },
            src: 'https://res.anxiaotang.com/assets/d273876b-2874-4d50-bf7a-7d623845c0e8',
            color: '#333',
            size: 16,
            direction: 'bottom',
            strokeColor: '#fff',
            offset: { x: 0, y: 0 },
            strokeWidth: 2
          })
        },
        geometries: []
      })
      this.marker = marker
      this.amap = map
      this.createMap = false
    },
    changLocation(val) {
      const location = this.locationOptions[val]
      this.longitude = location.location.lng
      this.latitude = location.location.lat
      this.locationTitle = location.title
      this.address = this.locationTitle
      this.form.setFieldsValue({ detail: location.address })
      this.initMarkers(location.title, location.location.lat, location.location.lng)
    },
    initMarkers(title, lat, lng) {
      const currentLatLng = new TMap.LatLng(lat, lng)
      this.marker.updateGeometries([
        {
          styleId: 'current',
          id: '1',
          position: currentLatLng,
          content: title // 标注点文本
        }
      ])
      this.amap.setCenter(currentLatLng)
    },
    location() {
      this.onSearch()
      if (this.createMap === true) {
        this.initMap()
      }
    },
    onSearch() {
      const name = this.form.getFieldValue('address_search')
      locationSearch({ keyword: name, page_size: 10 }).then(res => {
        if (res.code === 0 && res.data.status === 0) {
          this.locationOptions = res.data.data
        }
      })
    },
    getSmsCaptcha(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }
      this.form.validateFields(['apply_user_phone'], (err, values) => {
        if (!err) {
          if (values) {
            this.submitting = true
            sendSmsCaptcha({ phone_number: values.apply_user_phone }).then((res) => {
              if (res.code === 0) {
                this.validateBtn()
              }
            })
            this.submitting = false
          }
        }
      })
    },

    validateBtn() {
      // 倒计时
      let time = 60
      const timer = setInterval(() => {
        if (time === 0) {
          clearInterval(timer)
          this.disabled = false
          // eslint-disable-next-line no-unused-expressions
          this.btnTitle = '获取验证码'
        } else {
          this.btnTitle = time + '秒后重试'
          this.disabled = true
          time -= 1
        }
      }, 1000)
    },
    fetchUserOptions() {
      if (this.form.getFieldValue('destination_id')) {
        findTenantServiceOptions({ destination_id: this.form.getFieldValue('destination_id') }).then((res) => {
          if (res.code === 0) {
            this.userOptions = res.data
          }
        })
      } else {
        alertMessage({
          type: 'error',
          content: '请先选择目的地点'
        })
      }
    },
    handleChangeDead(e) {
      if (e.target.value === 'true') {
        this.isDead = true
      } else if (e.target.value === 'false') {
        this.isDead = false
      }
    }
  }
}
</script>

<style scoped>
  .margin-left-10 {
    margin-left: 10px;
  }
</style>
