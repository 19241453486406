import request from '@/utils/request'

// 发送短信验证码
export function sendSmsCaptcha(data) {
  return request({
    url: `/sms_captcha/send`,
    method: 'post',
    data
  })
}

// 验证短信验证码
export function verifySmsCaptcha(data) {
  return request({
    url: `/sms_captcha/verify`,
    method: 'post',
    data
  })
}
