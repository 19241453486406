var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-modal',{attrs:{"centered":"","mask-closable":false,"ok-button-props":{ props: { loading: _vm.submitting } },"title":"开单","width":"1300px"},on:{"ok":_vm.handleSubmit},model:{value:(_vm.isShow),callback:function ($$v) {_vm.isShow=$$v},expression:"isShow"}},[_c('a-form',{staticClass:"custom-compact-form",attrs:{"form":_vm.form,"label-col":{ span: 7 },"wrapper-col":{ span: 13 }},on:{"submit":_vm.handleSubmit}},[_c('a-row',[_c('a-col',{attrs:{"span":"12"}},[_c('div',{staticClass:"ant-row ant-form-item"},[_c('div',{staticClass:"ant-col ant-col-6 ant-form-item-label",staticStyle:{"font-size":"16px"}},[_vm._v("申请人信息")])]),_c('a-form-item',{attrs:{"label":"申请人"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['apply_user_name', {
                normalize: this.$lodash.trim,
                rules: [
                  { required: true, message: '请输入申请人真实姓名' },
                  { max: 30, message: '最多30个字符' } ]
              }]),expression:"['apply_user_name', {\n                normalize: this.$lodash.trim,\n                rules: [\n                  { required: true, message: '请输入申请人真实姓名' },\n                  { max: 30, message: '最多30个字符' },\n                ]\n              }]"}],attrs:{"autocomplete":"off"},on:{"change":_vm.changeApplyUser}})],1),_c('a-form-item',{attrs:{"label":"联系电话"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['apply_user_phone', {
                normalize: this.$lodash.trim,
                rules: [
                  { required: true, message: '请输入联系电话' },
                  { pattern: /^1[3-9]\d{9}$/, message: '联系电话格式不正确' },
                  { max: 11, message: '联系电话最多11位'} ]
              }]),expression:"['apply_user_phone', {\n                normalize: this.$lodash.trim,\n                rules: [\n                  { required: true, message: '请输入联系电话' },\n                  { pattern: /^1[3-9]\\d{9}$/, message: '联系电话格式不正确' },\n                  { max: 11, message: '联系电话最多11位'},\n                ]\n              }]"}],attrs:{"autocomplete":"off"},on:{"change":_vm.changeApplyUserPhone}})],1),_c('a-form-item',{attrs:{"label":"验证码"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['sms_captcha', {
                normalize: this.$lodash.trim,
                rules: [
                  { required: true, message: '请输入验证码' },
                  { max: 6, message: '最多6个字符' } ]
              }]),expression:"['sms_captcha', {\n                normalize: this.$lodash.trim,\n                rules: [\n                  { required: true, message: '请输入验证码' },\n                  { max: 6, message: '最多6个字符' },\n                ]\n              }]"}],staticStyle:{"width":"220px"},attrs:{"autocomplete":"off"}}),_c('a-button',{staticClass:"margin-left-10",attrs:{"type":"primary","disabled":_vm.disabled},on:{"click":_vm.getSmsCaptcha}},[_vm._v(" "+_vm._s(_vm.btnTitle)+" ")])],1),_c('a-form-item',{attrs:{"label":"与逝者关系"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['relation']),expression:"['relation']"}],attrs:{"placeholder":"请选择与逝者关系","allow-clear":"","mode":"combobox"}},_vm._l((_vm.relations),function(name){return _c('a-select-option',{key:name,attrs:{"value":name}},[_vm._v(" "+_vm._s(name)+" ")])}),1)],1),_c('div',{staticClass:"ant-row ant-form-item"},[_c('div',{staticClass:"ant-col ant-col-6 ant-form-item-label",staticStyle:{"font-size":"16px"}},[_vm._v("丧属信息 ")])]),_c('a-form-item',{attrs:{"label":"同申请人"}},[_c('a-checkbox',{on:{"change":_vm.changeSameToApply},model:{value:(_vm.sameToApply),callback:function ($$v) {_vm.sameToApply=$$v},expression:"sameToApply"}})],1),_c('a-form-item',{attrs:{"label":"丧属"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['master_name', {
                normalize: this.$lodash.trim,
                rules: [
                  { required: true, message: '请输入真实姓名' },
                  { max: 30, message: '最多30个字符' } ]
              }]),expression:"['master_name', {\n                normalize: this.$lodash.trim,\n                rules: [\n                  { required: true, message: '请输入真实姓名' },\n                  { max: 30, message: '最多30个字符' },\n                ]\n              }]"}],attrs:{"disabled":_vm.sameToApply,"autocomplete":"off"}})],1),_c('a-form-item',{attrs:{"label":"联系电话"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['master_phone_number', {
                normalize: this.$lodash.trim,
                rules: [
                  { required: true, message: '请输入联系电话' },
                  { pattern: /^1[3-9]\d{9}$/, message: '联系电话格式不正确' },
                  { max: 11, message: '联系电话最多11位'} ]
              }]),expression:"['master_phone_number', {\n                normalize: this.$lodash.trim,\n                rules: [\n                  { required: true, message: '请输入联系电话' },\n                  { pattern: /^1[3-9]\\d{9}$/, message: '联系电话格式不正确' },\n                  { max: 11, message: '联系电话最多11位'},\n                ]\n              }]"}],attrs:{"disabled":_vm.sameToApply,"autocomplete":"off"}})],1),_c('div',{staticClass:"ant-row ant-form-item"},[_c('div',{staticClass:"ant-col ant-col-6 ant-form-item-label",staticStyle:{"font-size":"16px"}},[_vm._v("接运信息")])]),_c('a-form-item',{attrs:{"label":"地址检索"}},[_c('a-input-search',{directives:[{name:"decorator",rawName:"v-decorator",value:(['address_search', {
                normalize: this.$lodash.trim,
                rules: [
                  { required: true, message: '请输入地点名称' },
                  { max: 6, message: '最多6个字符' } ]
              }]),expression:"['address_search', {\n                normalize: this.$lodash.trim,\n                rules: [\n                  { required: true, message: '请输入地点名称' },\n                  { max: 6, message: '最多6个字符' },\n                ]\n              }]"}],staticStyle:{"width":"100%"},attrs:{"enter-button":"定位"},on:{"search":_vm.location}})],1),_c('a-form-item',{attrs:{"label":"接运地点"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['origination', {
                rules: [
                  { required: true, message: '请选择接运地点' } ]
              }]),expression:"['origination', {\n                rules: [\n                  { required: true, message: '请选择接运地点' },\n                ]\n              }]"}],attrs:{"placeholder":"请选择接运地点"},on:{"change":_vm.changLocation}},_vm._l((_vm.locationOptions),function(location,index){return _c('a-select-option',{key:index,attrs:{"value":index}},[_vm._v(" "+_vm._s(location.title)+"("+_vm._s(location.address)+") ")])}),1)],1),_c('a-form-item',{attrs:{"label":"详细地址"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['detail', {
                rules: [
                  { max: 100, message: '最多100个字符'} ]
              }]),expression:"['detail', {\n                rules: [\n                  { max: 100, message: '最多100个字符'},\n                ]\n              }]"}],attrs:{"autocomplete":"off"}})],1),_c('a-form-item',{attrs:{"label":"目的地点"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['destination_id', {
                rules: [
                  { required: true, message: '请选择接目的地点' } ]
              }]),expression:"['destination_id', {\n                rules: [\n                  { required: true, message: '请选择接目的地点' },\n                ]\n              }]"}],attrs:{"placeholder":"请选择接目的地点"}},_vm._l((_vm.destinationOptions),function(destination){return _c('a-select-option',{key:destination.id,attrs:{"value":destination.id}},[_vm._v(" "+_vm._s(destination.name)+" ")])}),1)],1),_c('div',{staticClass:"ant-row ant-form-item"},[_c('div',{staticClass:"ant-col ant-col-6 ant-form-item-label",staticStyle:{"font-size":"16px"}},[_vm._v("备注")])]),_c('a-form-item',{attrs:{"label":"备注"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:(['remark', {
                normalize: this.$lodash.trim,
                rules: [
                  { max: 300, message: '最多300个字符' } ]
              }]),expression:"['remark', {\n                normalize: this.$lodash.trim,\n                rules: [\n                  { max: 300, message: '最多300个字符' },\n                ]\n              }]"}]})],1)],1),_c('a-col',{attrs:{"span":"12"}},[_c('div',{staticClass:"ant-row ant-form-item"},[_c('div',{staticClass:"ant-col ant-col-6 ant-form-item-label",staticStyle:{"font-size":"16px"}},[_vm._v("逝者信息")])]),_c('a-form-item',{attrs:{"label":"逝者信息"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['death_name', {
                normalize: this.$lodash.trim,
                rules: [
                  { required: true, message: '请输入逝者姓名' },
                  { max: 30, message: '最多30个字符' } ]
              }]),expression:"['death_name', {\n                normalize: this.$lodash.trim,\n                rules: [\n                  { required: true, message: '请输入逝者姓名' },\n                  { max: 30, message: '最多30个字符' },\n                ]\n              }]"}],attrs:{"autocomplete":"off"}})],1),_c('a-form-item',{attrs:{"label":"身份证号"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['identity_card_no', {
                normalize: this.$lodash.trim,
                rules: [
                  { pattern: /^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/, message: '身份证号格式不正确' } ]
              }]),expression:"['identity_card_no', {\n                normalize: this.$lodash.trim,\n                rules: [\n                  { pattern: /^[1-9]\\d{5}[1-9]\\d{3}((0\\d)|(1[0-2]))(([0|1|2]\\d)|3[0-1])\\d{3}([0-9]|X)$/, message: '身份证号格式不正确' },\n                ]\n              }]"}],attrs:{"autocomplete":"off"},on:{"change":_vm.calAge}})],1),_c('a-form-item',{attrs:{"label":"性别"}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:(['sex',{initialValue:0},
                            {rules: [
                              { required: true, message: '请选择逝者性别' } ]}]),expression:"['sex',{initialValue:0},\n                            {rules: [\n                              { required: true, message: '请选择逝者性别' },\n                            ]}]"}]},_vm._l((_vm.sexOptions),function(option,index){return _c('a-radio',{key:index,attrs:{"value":option.value}},[_vm._v(_vm._s(option.text))])}),1)],1),_c('a-form-item',{attrs:{"label":"年龄"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:(['age', {
                normalize: this.$lodash.trim,
                rules: [
                  { required: true, message: '请输入逝者年龄' } ]
              }]),expression:"['age', {\n                normalize: this.$lodash.trim,\n                rules: [\n                  { required: true, message: '请输入逝者年龄' },\n                ]\n              }]"}],staticStyle:{"width":"100%"},attrs:{"min":1,"max":999,"step":1,"precision":0}})],1),_c('a-form-item',{attrs:{"label":"宗教信仰"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['religion',{
                rules: [ { required: true, message: '请选择宗教信仰' } ]
              }]),expression:"['religion',{\n                rules: [ { required: true, message: '请选择宗教信仰' },]\n              }]"}],attrs:{"placeholder":"请选择宗教信仰","allow-clear":"","mode":"combobox"}},_vm._l((_vm.religionOptions),function(name){return _c('a-select-option',{key:name,attrs:{"value":name}},[_vm._v(" "+_vm._s(name)+" ")])}),1)],1),_c('a-form-item',{attrs:{"label":"是否已逝世"}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:(['dead',{initialValue:'false'},
                            {rules: [
                              { required: true, message: '请选择是否已逝世' } ]}]),expression:"['dead',{initialValue:'false'},\n                            {rules: [\n                              { required: true, message: '请选择是否已逝世' },\n                            ]}]"}],on:{"change":_vm.handleChangeDead}},[_c('a-radio',{attrs:{"value":"true"}},[_vm._v("是")]),_c('a-radio',{attrs:{"value":"false"}},[_vm._v("否")])],1)],1),(_vm.isDead === true)?_c('a-form-item',{attrs:{"label":"逝世时间"}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'death_date',
                {
                  rules: [{ required: true, message: '请选逝世时间' }],
                } ]),expression:"[\n                'death_date',\n                {\n                  rules: [{ required: true, message: '请选逝世时间' }],\n                },\n              ]"}],staticStyle:{"width":"100%"},attrs:{"format":"YYYY-MM-DD HH:mm","show-time":{ format: 'HH:mm' },"placeholder":"请选逝世时间"}})],1):_vm._e(),(_vm.isDead === true)?_c('a-form-item',{attrs:{"label":"逝世原因"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['dead_reason', {
                rules: [
                  { required: true, message: '请选择逝世原因' } ]
              }]),expression:"['dead_reason', {\n                rules: [\n                  { required: true, message: '请选择逝世原因' },\n                ]\n              }]"}],attrs:{"placeholder":"请选择逝世原因"}},_vm._l((_vm.deadReasonOptions),function(reason){return _c('a-select-option',{key:reason.value,attrs:{"value":reason.value}},[_vm._v(" "+_vm._s(reason.text)+" ")])}),1)],1):_vm._e(),(_vm.isDead === true)?_c('a-form-item',{attrs:{"label":"逝者身上有无贵重物品"}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:(['has_valuables',{initialValue:'false'},{rules: [
                { required: true, message: '请选择逝者身上有无贵重物品' } ]}]),expression:"['has_valuables',{initialValue:'false'},{rules: [\n                { required: true, message: '请选择逝者身上有无贵重物品' },\n              ]}]"}]},[_c('a-radio',{attrs:{"value":"true"}},[_vm._v("有")]),_c('a-radio',{attrs:{"value":"false"}},[_vm._v("无")])],1),_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:(['valuables_content', {
                normalize: this.$lodash.trim,
                rules: [
                  { max: 300, message: '最多300个字符' } ]
              }]),expression:"['valuables_content', {\n                normalize: this.$lodash.trim,\n                rules: [\n                  { max: 300, message: '最多300个字符' },\n                ]\n              }]"}],attrs:{"placeholder":"若有，请仔细填写物品名称、数量(必填)，若因未填写清楚产生的法律问题纠纷，平台概不负责；若无则不需填写"}})],1):_vm._e(),(_vm.isDead === true)?_c('a-form-item',{attrs:{"label":"逝者身上是否有伤口破损"}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:(['damaged',{initialValue:'false'},{rules: [
                { required: true, message: '请选择逝者身上是否有伤口破损' } ]}]),expression:"['damaged',{initialValue:'false'},{rules: [\n                { required: true, message: '请选择逝者身上是否有伤口破损' },\n              ]}]"}]},[_c('a-radio',{attrs:{"value":"true"}},[_vm._v("有")]),_c('a-radio',{attrs:{"value":"false"}},[_vm._v("无")])],1),_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:(['damaged_content', {
                normalize: this.$lodash.trim,
                rules: [
                  { max: 300, message: '最多300个字符' } ]
              }]),expression:"['damaged_content', {\n                normalize: this.$lodash.trim,\n                rules: [\n                  { max: 300, message: '最多300个字符' },\n                ]\n              }]"}],attrs:{"placeholder":"若有，请仔细描述伤口位置、数量(必填)，若因未填写清楚产生的法律问题纠纷，平台概不负责；若无则不需填写"}})],1):_vm._e(),_c('a-form-item',{attrs:{"label":"订单负责人"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['user_id', {
                rules: [
                  { required: true, message: '请选择订单负责人' } ]
              }]),expression:"['user_id', {\n                rules: [\n                  { required: true, message: '请选择订单负责人' },\n                ]\n              }]"}],attrs:{"placeholder":"请选择订单负责人"},on:{"focus":_vm.fetchUserOptions}},_vm._l((_vm.userOptions),function(user){return _c('a-select-option',{key:user.id,attrs:{"value":user.id}},[_vm._v(" "+_vm._s(user.emp_name)+" ")])}),1)],1)],1)],1)],1),_c('div',{ref:"container"})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }